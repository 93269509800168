<template>
  <section class="section">
    <h1 class="title has-text-centered">Odd Giants Forums</h1>
    <b-table
      :data="forums"
      :striped="true"
      :hoverable="true"
      :loading="loadingForums"
      class="mt-4 topic-table"
      @click="goToForum"
    >
      <b-table-column field="title" label="Title" v-slot="props">
        <p>
          <strong>{{ props.row.title }}</strong>
          <br />
          {{ props.row.description }}
        </p>
      </b-table-column>

      <b-table-column field="topics_count" label="Topics" v-slot="props">
        <p>
          <strong>{{ props.row.topics_count }}</strong>
        </p>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'home',
  computed: {
    ...mapState('forum', ['loadingForums', 'forums']),
  },
  mounted() {
    this.$store.dispatch('forum/unselectForum');
  },
  methods: {
    goToForum(row) {
      this.$router.push({ path: `/forums/${row.slug}` });
    },
  },
};
</script>

<style>
.topic-table tr {
  cursor: pointer;
}
</style>
